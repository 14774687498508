.loyalty-status-wrapper {
  .breadcrumb,
  .loyalty-status {
    background: #ebf7f3;
    @if $cr19 == true {
      background: $color-white;
    }
  }
  .breadcrumb {
    border-top: 1px solid $color-lightest-grey;
  }

  $phone-up: 'screen and (min-width: 445px)';
  $phone: 'screen and (max-width: 445px)';
  .loyalty-status {
    border-bottom: 1px solid $color-lightest-grey;
    .outer-wrap {
      margin-bottom: 15px;
      padding-top: 10px;
      @media #{$phone-up} {
        padding-bottom: 100px;
        margin-right: 15px;
        background-image: url('/media/export/cms/loyalty/loyalty-logo.png');
        background-size: 200px;
        background-repeat: no-repeat;
        background-position: right bottom;
      }
      @media #{$medium-up} {
        padding-top: 30px;
        margin-right: auto;
        padding-bottom: 100px;
        background-size: 315px;
        background-position: right 15px;
      }
    }
    h1,
    p,
    .link {
      @media #{$medium-up} {
        margin-right: 350px;
      }
    }
    h1 {
      @media #{$phone} {
        padding-top: 15px;
      }
      @media #{$phone-up} {
        padding-top: auto;
      }
      margin-bottom: 0;
    }
    p {
      margin-bottom: 15px;
      font-size: 1.7em;
      font-family: $helvetica-light-font-family;
      &.last {
        margin-bottom: 60px;
      }
    }
    .link a {
      padding: 5px 10px;
      font-size: 1em;
      color: #ffffff;
      background: $color-cl-green-2;
      margin-right: 10px;
      @if $cr19 == true {
        background-color: $cr19-bg-black;
        color: $cr19-text-white;
      }
      @media #{$medium-up} {
        margin-right: 40px;
      }
      &:hover {
        background: #ffffff;
        color: $color-cl-green-2;
        @if $cr19 == true {
          background-color: $cr19-bg-med;
          color: $cr19-text-white;
        }
      }
    }
  }
}
