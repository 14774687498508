/**
 * @file us_pc.scss
 *
 * \brief Pull all parts of the US-PC theme together into one file
 */

@import 'overrides/overrides';

@import '../../cl_base/scss/cl-base-pc';
@import '../../emea_base/scss/emea_base_pc';
@import 'gr_fonts';
@import 'gr_variables';

@import 'components/global';
@import 'sections/power-review/power_reviews_pc';
@import '../../cl_base/scss/loyalty/loyalty_samples';
@import '../../cl_base/scss/standalone/perlgem/account_loyalty';
@import 'sections/_gwp_pc';
@import 'sections/account_reset_password';
@import 'sections/enhanced-delivery';
@import 'components/loyalty_global';
@import '../../emea_base/scss/emea_gdpr_shared';
@import 'components/_clinical_reality';
@import 'sections/_all';

.samples {
  li.sample-box {
    position: relative;
  }
  .sample_close img {
    width: 100%;
  }
  .sample-box .sample_close {
    display: none;
    width: 15px;
    position: absolute;
    #{$rdirection}: 0;
    @include swap_direction(margin, 5px 5px 0px 0px);
  }
}

.header-gnav-section__link,
.header-gnav-section__label,
.text-link--style-2,
.footer-links,
.footer-country-chooser__selectbox,
.sitewide-footer-formatter__copyright {
  font-weight: 900;
}
