body {
  .green-copy {
    color: $color-cl-green-2;
  }
  #gnav_signin,
  #main {
    .signin_container {
      .inner-block {
        .loyalty__block {
          width: 53%;
          float: #{$ldirection};
          @include swap_direction(margin, 0 0 17px 65px);
          background: url('/media/export/cms/Clinique_Loyalty_NA/5_19_CL_enrollgnav_register_v3.png') left bottom;
          background-repeat: no-repeat;
          padding-#{$ldirection}: 100px;
          background-size: contain;
          position: relative;
          .loyalty__make {
            font-family: $base-bold-font-family;
            color: $color-black;
            font-size: 21px;
          }
          .loyalty__our {
            @include swap_direction(margin, 0 0 6px 0);
            font-size: 30px;
          }
          .loyalty__text {
            font-size: 19px;
            color: $color-black;
          }
          .loyalty__subdivision {
            .loyalty__subdivision__left_block {
              display: inline-block;
              width: 47%;
              float: #{$ldirection};
              ul {
                list-style-type: disc;
                font-size: 13px;
                color: $color-black;
                @include swap_direction(padding, 15px 0 15px 15px);
                li {
                  line-height: 2;
                }
              }
            }
            .loyalty__subdivision__right_block {
              display: inline-block;
              float: #{$rdirection};
              position: absolute;
              top: 0px;
              right: -15px;
              @media #{$ipad-portrait-only} {
                right: -20px;
              }
              @media #{$ipad-landscape-only} {
                right: -20px;
              }
              .loyal_image {
                width: 220px;
                @include swap_direction(margin, 9px 0 0 0);
                @media #{$ipad-portrait-only} {
                  width: 190px;
                }
                @media #{$ipad-landscape-only} {
                  width: 190px;
                }
              }
            }
          }
          @media #{$ipad-portrait-only} {
            @include swap_direction(margin, 0 0 17px 2%);
            @include swap_direction(padding, 0 0 0 10%);
          }
        }
        .login-email {
          width: 39%;
          float: #{$ldirection};
          form {
            .form-item,
            .row {
              &.privacy-form-container,
              &.email_promotions,
              &.loyalty {
                input[type='checkbox'] {
                  @include swap_direction(margin, 4px 0 0 0);
                  vertical-align: top;
                }
              }
            }
          }
        }
        .privacy-form-container.row {
          .register-terms,
          .loyalty-terms {
            width: 80%;
          }
          .loyalty__rewards {
            font-weight: bold;
          }
          .loyalty__offer_email {
            width: auto;
            padding-#{$ldirection}: 16px;
            font-size: 11px;
          }
        }
        .email_promotions.row .register-email-offers {
          width: 80%;
        }
      }
      .pane {
        @include swap_direction(padding, 25px 0 20px 0);
      }
    }
    .topnav-pane {
      .fourcol,
      .twocol {
        @include swap_direction(margin, 40px 0 0 0);
      }
      .twocol {
        .avatar {
          position: absolute;
          top: 0px;
          #{$rdirection}: 250px;
          .thumb {
            width: 60px;
          }
          .bio {
            float: #{$rdirection};
          }
          .row {
            .twocol {
              position: relative;
              top: 0px;
              @include swap_direction(padding, 0);
              @include swap_direction(margin, 5px 0 0 0);
              width: 100%;
              .signout {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
  .account-page {
    #sms_verification {
      fieldset {
        margin: 0 2px 10px;
        border: none;
        p {
          margin-bottom: 6px;
        }
      }
    }
  }
  &#my_points {
    #loyalty__panel__points .account-loyalty {
      .level.current-level-0 {
        .star-points-benifits {
          display: none;
        }
      }
    }
  }
  &#past_purchases {
    .past-purchases .order-info {
      .shipments-list .product {
        .my-account-sku[redemption_sku~='1'] {
          .price {
            display: none;
          }
        }
      }
    }
  }
  #overlay-background {
    background-color: $color-white;
    opacity: 0.7;
  }
  .product-fave-overlay-cbox {
    @media #{$small-only} {
      border: none;
    }
    border: 1px solid $color-dark-grey;
    .close-container {
      top: 30px;
      right: 30px;
    }
    .loyalty__popup {
      @media #{$small-only} {
        @include swap_direction(padding, 0);
        @include swap_direction(margin, 20px 0 0 0);
      }
      &.left {
        @media #{$small-only} {
          width: 100%;
          @include swap_direction(padding, 0);
          @include swap_direction(margin, 0);
        }
        width: 42%;
        float: #{$ldirection};
        text-align: center;
        img {
          width: 100%;
          @media #{$small-only} {
            position: absolute;
            z-index: -1;
            right: 0;
            width: 165px;
          }
        }
      }
      &.right {
        float: #{$rdirection};
        width: 55%;
        font-size: 18px;
        @include swap_direction(padding, 40px 0);
        @include swap_direction(margin, 0 0 0 15px);
        .loyalty_post_signin_terms {
          font-size: 12px;
          line-height: 12px;
        }
        @media #{$small-only} {
          width: 100%;
          font-size: 14px;
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 0);
        }
        h2 {
          font-size: 30px;
          font-weight: normal;
          @include swap_direction(margin, 0 0 10px 0);
          @media #{$small-only} {
            font-size: 30px;
            width: 180px;
          }
        }
        h3 {
          color: $color-black;
          font-weight: bold;
          font-size: 19px;
          font-family: $base-font-family-roman;
          @media #{$small-only} {
            color: $color-black;
            font-weight: bold;
            font-size: 22px;
            font-family: $base-font-family-roman;
            width: 180px;
            @include swap_direction(margin, 20px 0 20px 0);
          }
        }
        p {
          @media #{$small-only} {
            @include swap_direction(margin, 10px 0 0 0);
          }
        }
      }
      &.right_content {
        @media #{$small-only} {
          @include swap_direction(padding, 10px 10px);
          @include swap_direction(margin, 0 0 0 5px);
        }
        @include swap_direction(padding, 16px 16px);
        li {
          @media #{$small-only} {
            list-style-type: disc;
            @include swap_direction(padding, 2px 0);
          }
          list-style-type: disc;
          @include swap_direction(padding, 5px 0);
        }
      }
      &.button {
        @media #{$small-only} {
          @include swap_direction(margin, 0 0 10px 0);
          @include swap_direction(padding, 0);
          font-size: 18px;
        }
        font-size: 21px;
        @include swap_direction(padding, 5px 30px 5px 30px);
      }
      &.join_loyalty_btn {
        display: block;
        font-size: 11px;
        @include swap_direction(padding, 15px 0);
        @media #{$small-only} {
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 0);
          font-size: 14px;
        }
      }
      .terms_conditions {
        font-size: 11px;
      }
    }
  }
  // My Account Landing Tout
  .logged-in {
    .subheader {
      .program_name {
        font-size: 25px;
        margin-top: 8px;
        margin-bottom: 0px;
      }
    }
    .twocol {
      width: 35%;
      .gnav-loyalty-join-block {
        @include swap_direction(margin, 25px 0 0 0);
        display: inline-block;
        .text-wrapper {
          width: 45%;
          float: #{$ldirection};
          .header {
            font-weight: bold;
            margin-bottom: 8px;
            color: $color-black;
            width: 170px;
          }
          .text {
            font-size: 14px;
          }
          .button-wrapper {
            display: block;
            .button {
              @include swap_direction(padding, 5px 25px);
              @include swap_direction(margin, 25px 0 0 0);
              background-color: $color-black;
            }
          }
        }
        .image-wrapper {
          width: 50%;
          float: #{$rdirection};
          margin-top: 40px;
        }
      }
      #my-account-loyalty-progress-section .account-loyalty {
        width: auto;
      }
    }
    .fourcol {
      width: 63%;
    }
  }
  &.section-terms-conditions-loyalty {
    .main article .field-content {
      margin: 0 auto;
      max-width: 960px;
      overflow: hidden;
      padding: 0;
      #loyalty_tc_title {
        margin: 40px 0 0;
        .sup-title,
        .title {
          text-align: center;
        }
        .title {
          font-size: 36px;
        }
      }
      #loyalty_tc_copy {
        #intro {
          font-weight: bold;
        }
        ol {
          counter-reset: item;
        }
        li {
          display: block;
        }
        li:before {
          content: counters(item, '.') '.';
          counter-increment: item;
          position: absolute;
        }
        li > h4 {
          display: inline-block;
          font-weight: bold;
          color: $color-black;
          margin-left: 1.5em;
          margin-bottom: 1em;
        }
        li span {
          margin-left: 2.5em;
          display: inline-block;
        }
        ol > li {
          margin-bottom: 2em;
          margin-left: 1em;
        }
        .level1 > ol,
        .level2 > ol {
          margin-left: 1em;
        }
        .level2,
        .level3 {
          margin-bottom: 1em;
        }
        .level3 {
          margin-left: 2em;
        }
      }
    }
  }
  &.section-clinique-bonus {
    #main #content {
      #bonus-intro {
        .loyalty_market_smart_left {
          border-bottom: 1px solid $color-light-grey;
          background: url('/media/export/cms/loyalty_market_left_bg.png') top left no-repeat;
          background-size: contain;
          @media #{$small-only} {
            overflow: hidden;
            background: url('/media/export/cms/marking_landing_left.png') top left no-repeat;
          }
          .loyalty_market_smart_right {
            background: url('/media/export/cms/loyalty_market_right_bg.png') top right no-repeat;
            min-height: 630px;
            @media #{$small-only} {
              background: url('/media/export/cms/marking_landing_right.png') top right no-repeat;
              min-height: 610px;
            }
            background-size: contain;
            .outer-wrap {
              @include swap_direction(padding, 120px 0 0 100px);
              @media #{$small-only} {
                @include swap_direction(padding, 60px 5% 20px 5%);
                overflow: hidden;
              }
              .clinique_logo {
                @include swap_direction(padding, 0 0 30px 0);
              }
              .button {
                font-size: 18px;
              }
              .loyalty_earn_terms {
                clear: both;
                @include swap_direction(padding, 150px 0 20px);
                .link {
                  color: $color-black;
                  font-weight: bold;
                  font-family: $base-font-family-roman;
                  text-decoration: underline;
                }
                @media #{$small-only} {
                  @include swap_direction(padding, 100px 0 0 0);
                }
              }
              h2 {
                color: $color-black;
                font-size: 38px;
                font-family: $base-bold-font-family;
                margin: 0px;
                @include swap_direction(padding, 8px 0 0 0);
              }
              p {
                @include swap_direction(padding, 20px 0);
                @include swap_direction(margin, 0);
                line-height: 27px;
                font-size: 18px;
                &.loyalty_market_rewards {
                  padding-top: 0px;
                }
              }
              .loyality_sign_off {
                font-size: 19px;
                @include swap_direction(padding, 0 0 30px 0);
              }
              .loyalty_join_button,
              .loyalty_signin {
                float: #{$ldirection};
                .btn_join {
                  display: block;
                  background: $color-black;
                  @include swap_direction(padding, 12px 50px);
                  @include swap_direction(margin, 0 35px 0 0);
                  font-size: 18px;
                  font-weight: normal;
                  border: 0px;
                  color: $color-white;
                }
                .btn_sign_in {
                  display: block;
                  background: $color-white;
                  border: 2px solid $color-black;
                  @include swap_direction(padding, 10px 50px);
                  @include swap_direction(margin, 0);
                  font-size: 18px;
                  font-weight: bold;
                }
                @media #{$small-only} {
                  width: 50%;
                  button {
                    margin: 0;
                    font-size: 17px;
                  }
                  .btn_join {
                    padding: 7% 18% 8%;
                    @include swap_direction(margin, 0 5% 0 0);
                  }
                  .btn_sign_in {
                    @include swap_direction(padding, 6% 22%);
                  }
                }
              }
            }
          }
        }
      }
      #bonus-info {
        padding: 0 5%;
        text-align: center;
        margin: 10px 0;
        h3 {
          font-size: 18px;
          margin-top: 10px;
          color: $color-black;
          @include swap_direction(margin, 10px 0 0 0);
          font-family: $base-font-family-roman;
          a.earning_points {
            color: $color-cl-green-2;
          }
        }
      }
      .pcg-product-image-title {
        .product:hover {
          .btn-quickview {
            display: none;
          }
        }
      }
      .block-template-header-nav-row-uk-v1 {
        border-bottom: 1px solid $color-light-grey;
        .pcg-image-v1 {
          float: left;
          width: 50%;
          text-align: center;
          margin: 35px 0 25px;
        }
        .header-nav-row-v1 {
          line-height: 1;
          list-style-type: none;
          max-width: 1140px;
          padding: 0 5%;
          text-align: center;
          font-size: 35px;
          margin: 25px auto 30px;
          clear: both;
          a {
            cursor: default;
            color: $color-black;
            &:hover {
              color: $color-black;
            }
          }
        }
      }
      .basic-elc-nodeblock-noderef-v1 {
        .product {
          .product-image img {
            margin: auto;
          }
        }
        &:nth-of-type(2) {
          .header-nav-row-v1 {
            a,
            a:hover {
              color: $color-loyalty-lvl1;
            }
          }
          .product {
            .button.cta {
              background-color: $color-loyalty-lvl1;
            }
          }
        }
        &:nth-of-type(3) {
          .header-nav-row-v1 {
            a,
            a:hover {
              color: $color-loyalty-lvl2;
            }
          }
          .product-tout {
            .product {
              &-image {
                height: 190px;
                overflow: hidden;
                img {
                  margin: -30px auto auto auto;
                }
              }
              .button.cta {
                background-color: $color-loyalty-lvl2;
              }
            }
          }
        }
        &:nth-of-type(4) {
          .header-nav-row-v1 {
            a,
            a:hover {
              color: $color-loyalty-lvl3;
            }
          }
          .product {
            .button.cta {
              background-color: $color-loyalty-lvl3;
            }
          }
        }
        &:nth-of-type(5) {
          .header-nav-row-v1 {
            a,
            a:hover {
              color: $color-loyalty-lvl4;
            }
          }
          .product {
            .button.cta {
              background-color: $color-loyalty-lvl4;
            }
          }
        }
        &:nth-of-type(6) {
          .header-nav-row-v1 {
            a,
            a:hover {
              color: $color-loyalty-lvl5;
            }
          }
          .product {
            .button.cta {
              background-color: $color-loyalty-lvl5;
            }
          }
        }
        .header-nav-row-v1 {
          font-size: 32px;
          margin: 15px auto 10px;
          a span {
            font-family: $cpHelveticaLight, Arial, sans-serif;
          }
        }
        .field-elc-nodeblock-content > .product-tout {
          width: 340px;
          margin: auto;
          .product {
            .product-info {
              padding: 0 20px;
              text-align: center;
              .product-title a {
                font-size: 15px;
                line-height: 17px;
              }
            }
            .action-quickview {
              margin-bottom: 15px;
            }
          }
        }
        .product-content-carousel-quickview {
          margin-bottom: 1em;
          .flexslider {
            padding-top: 10px;
            .carousel-title-container {
              margin-bottom: 0px;
            }
            @media #{$medium-only} {
              .flex-direction-nav {
                .flex-prev {
                  #{$ldirection}: 10px;
                }
                .flex-next {
                  #{$rdirection}: 10px;
                }
              }
            }
            .flex-viewport li .inner {
              margin-bottom: 0px;
              height: auto !important;
              .product {
                margin-bottom: 0px;
                &-image {
                  height: 160px;
                  img {
                    margin-top: -20px;
                  }
                }
                &-info {
                  padding: 0 20px;
                  text-align: center;
                  margin-top: 5px;
                  @media #{$medium-only} {
                    margin-top: 0;
                  }
                  .product-title {
                    line-height: 17px;
                    height: 40px;
                    a {
                      font-size: 15px;
                      line-height: 17px;
                    }
                  }
                }
                .cta.button {
                  visibility: visible;
                  margin: 10px 28% 0 28%;
                  width: unset;
                  display: block;
                  text-align: center;
                }
                .product-image img {
                  max-width: 100%;
                }
              }
            }
          }
          &.columns-4 {
            .flexslider .flex-viewport li .inner {
              .product .cta.button {
                margin: 10px 28% 0 28%;
                @include breakpoint(768px) {
                  margin: 10px 20% 0 20%;
                }
              }
            }
          }
          &.columns-5 {
            .flexslider .flex-viewport li .inner {
              .product .cta.button {
                margin: 10px 22% 0 22%;
                @include breakpoint(768px) {
                  margin: 10px 13% 0 13%;
                }
              }
            }
          }
        }
        .product-content-grid-v1 {
          background-image: none;
          padding-top: 10px;
          padding-bottom: 30px;
        }
        .block-template-pcg-sku-image-title-v1 {
          div:not(.lp-level1) {
            .product-tout {
              margin-bottom: 20px;
            }
          }
        }
        .lp-level5 {
          margin-bottom: 30px;
        }
      }
    }
  }
  &.page-products-loyaltygiftslevel1,
  &.page-products-loyaltygiftslevel2,
  &.page-products-loyaltygiftslevel3,
  &.page-products-loyaltygiftslevel4,
  &.page-products-loyaltygiftslevel5 {
    .cl-mpp-product-layout-v1 .mpp-grid {
      ul li.mpp-box {
        .mpp-product-hover-view {
          min-height: 300px;
          .btn-shopnow {
            margin: 0px;
            display: none;
          }
        }
      }
    }
  }
  &.page-product-loyaltygiftslevel1,
  &.page-product-loyaltygiftslevel2,
  &.page-product-loyaltygiftslevel3,
  &.page-product-loyaltygiftslevel4,
  &.page-product-loyaltygiftslevel5 {
    header {
      &#sticky-bar {
        .sticky_prod_select {
          a.btn-add-to-bag {
            display: none;
          }
        }
      }
    }
    .page-spp {
      .breadcrumbs {
        visibility: hidden;
      }
      .col1 {
        .spp-share-container.bvflow_hide {
          display: none;
        }
      }
      .options {
        .btn-add-to-bag {
          display: none;
        }
        .icon-heart {
          display: none;
        }
      }
    }
  }
  &.page-product-loyaltygiftslevel3,
  &.page-product-loyaltygiftslevel5 {
    .page-spp {
      .col1 {
        &.product-full__image {
          overflow: hidden;
          .full.product-img {
            margin-top: -90px;
          }
        }
      }
    }
  }
  &.customer-care-loyalty {
    .sub-heading {
      margin-left: 10px;
      font-size: 16px;
    }
  }
  #footer {
    .footer-bottom {
      .loyalty_content {
        &.hidden {
          display: none;
        }
      }
    }
  }
  .account-loyalty {
    .section-content {
      .loyalty__panel__points__bg__slide-container {
        .loyalty__panel__points__bg__Bar-container {
          width: 95%;
        }
        .loyalty__panel__points__bg__Bar-container .arrow-down {
          &.level-1 {
            border-top-color: $color-loyalty-lvl1;
          }
          &.level-2 {
            border-top-color: $color-loyalty-lvl2;
          }
          &.level-3 {
            border-top-color: $color-loyalty-lvl3;
          }
          &.level-4 {
            border-top-color: $color-loyalty-lvl4;
          }
          &.level-5 {
            border-top-color: $color-loyalty-lvl5;
          }
        }
      }
    }
    .loyalty__panel__points__bg__slide1 {
      .loyalty__panel__points__bg__slide__smart {
        &.level-1 {
          background-color: $color-loyalty-lvl1;
        }
        &.level-2 {
          background-color: $color-loyalty-lvl2;
        }
        &.level-3 {
          background-color: $color-loyalty-lvl3;
        }
        &.level-4 {
          background-color: $color-loyalty-lvl4;
        }
        &.level-5 {
          background-color: $color-loyalty-lvl5;
        }
      }
    }
  }
}
