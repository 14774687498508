@if $password_strength {
  .password-field {
    &__info {
      @media #{$medium-up} {
        #signin &,
        #gnav_signin,
        #billing & {
          width: 28%;
        }
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        font-size: 11px;
      }
    }
  }
  #gnav_signin {
    .signin_container {
      .row {
        overflow: visible;
      }
    }
    .password-field {
      &__info {
        @media #{$medium-up} {
          width: 45%;
        }
      }
    }
  }
}
