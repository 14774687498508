.clinical-reality__modules--recommended-products {
  .clinical-reality__recommended-products {
    &--description {
      @include start-breakpoint($medium-min) {
        width: 55%;
      }
    }
  }
}

.clinical-reality {
  .mobile_hidden {
    @include start-breakpoint($medium-m) {
      max-height: inherit;
    }
  }
  .pc_hidden {
    @include start-breakpoint($small-m) {
      max-height: 500px;
    }
  }
  &__save-results {
    .clinical-reality__module {
      &--title-copy {
        font-family: $cpHelveticaLight;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}

.section-clinicalreality {
  .nav-promo {
    @include start-breakpoint($medium-min) {
      display: none;
    }
  }
  .main {
    clear: both;
  }
}

.device-mobile {
  h3.clinical-reality__module--title {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
  }
}
