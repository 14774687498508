.section-clinique-bonus {
  .slide-content-panel {
    .body-text {
      ul {
        margin-left: 9%;
        padding: 7px 0;
        list-style-type: disc;
      }
      li {
        font-size: 15px;
        padding: 7px 0;
      }
    }
  }
  #points_perks {
    width: 1142px;
    margin: 0 auto;
    h2 {
      padding: 50px 0 0;
      &.market_perks {
        padding-top: 0;
        font-size: 18px;
      }
    }
    .smartbar {
      .pointsbar_bg {
        height: 25px;
        background: $color-ly-dark-green;
      }
      span {
        color: $color-ly-dark-green;
      }
    }
    .brilliantbar {
      .pointsbar_bg {
        height: 25px;
        background: $color-ly-dark-purple;
      }
      span {
        color: $color-ly-dark-purple;
      }
    }
    .geniusbar {
      .pointsbar_bg {
        height: 25px;
        background: $color-ly-dark-orange;
      }
      span {
        color: $color-ly-dark-orange;
      }
    }
    .brilliantbar,
    .geniusbar,
    .smartbar {
      @media #{$medium-up} {
        width: 32.3%;
      }
      float: left;
      margin: 0 1% 0 0;
      .brilliantbar_pts,
      .geniusbar_pts,
      .smartbar_pts {
        padding: 0 25px 0 0;
        p {
          font-family: $cpHelveticaLight, Arial, sans-serif !important;
          line-height: 24px;
          font-size: 15px;
          padding: 7px 0;
          color: $color-cl-soft-black;
        }
      }
      span {
        font-size: 35px;
        margin: 35px 0 20px;
        float: left;
        width: 100%;
        font-family: $cpHelveticaBold, Arial, sans-serif !important;
      }
    }
  }
}
