.spc_enabled {
  .enhanced-delivery-page:not([class*='enhanced_signin_page']) {
    #main.single {
      .responsive-container {
        .pg_wrapper {
          @media #{$medium-up} {
            margin: 70px auto 0;
          }
          .column.left {
            #review-panel {
              .payment-method-container {
                .field-container {
                  .payment-type {
                    &:last-child {
                      border-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .pg_wrapper {
    .checkout-confirmation-page {
      @media #{$medium-up} {
        margin: 70px auto 0;
      }
    }
  }
}
