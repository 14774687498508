/* -----Power Reviews------ */
#pr-review-display {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Ναί';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Όχι';
              }
            }
          }
          .pr-rd-flag-review-container {
            width: auto;
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          width: 50%;
          &:before {
            content: 'Φιλτράρετε με:';
          }
        }
        .pr-rd-main-header-search-sort {
          width: 49%;
        }
        .pr-rd-review-header-sorts {
          width: 61%;
          .pr-rd-sort-group {
            float: right;
          }
        }
        .pr-rd-sort {
          background: url('/media/images/stars/down-arrow.png') no-repeat scroll 100% 6px transparent;
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-sort-group {
          &:before {
            content: 'Ταξινόμηση κατά:';
          }
        }
      }
      .pr-rd-sort-group {
        &:before {
          content: 'Ταξινόμηση κατά:';
        }
      }
      .pr-multiselect {
        .pr-multiselect-button {
          padding: 8px 23px 8px 1px;
          margin: 5px 13px 5px 0;
        }
      }
      .pr-rd-main-header-with-filters {
        .pr-rd-search-container {
          .pr-rd-search-reviews-input {
            input {
              width: 176px !important;
            }
          }
        }
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .tag-group {
      .pr-label-control {
        white-space: initial;
      }
    }
  }
}

.mpp-grid {
  .reviews {
    .p-w-r {
      .pr-snippet {
        .pr-category-snippet__rating,
        .pr-category-snippet__total,
        .pr-snippet-stars-container,
        .pr-snippet-read-and-write {
          display: block;
          margin-top: 0;
        }
        .pr-snippet-stars-container {
          float: none;
        }
      }
      .pr-snippet-stars-reco-inline {
        &.pr-snippet-minimal {
          .pr-snippet-read-and-write {
            .pr-snippet-review-count {
              left: 0;
            }
          }
        }
      }
    }
  }
}

.ecommerce {
  .content {
    .page-spp.spp_reviews {
      display: block;
    }
  }
}

.elc-reviews {
  .tabs-nav {
    li {
      border-right: 1px solid $color-light-grey !important;
      &:last-child {
        display: none;
      }
    }
  }
}
