/* overrides */

// usage @media #{$ipad-portrait-only} {}
// usage @media #{$ipad-landscape-only} {}
$ipad-portrait-only: '#{$medium-only} and (orientation: portrait)' !default;
$ipad-landscape-only: '#{$medium-only} and (orientation: landscape)' !default;

.navigation-right .mm-menu li span.title {
  padding: 0 5px;
}

p#providing-email {
  clear: both;
}

.payment-properties.radio-buttons input[name='PAYMENT_OPTION'] {
  float: left;
  display: inline-block;
  margin-top: 24px;
  margin-right: 10px;
}

.pc_spp_view {
  .module-spp-detail {
    .page-spp {
      .container {
        .col2 {
          .support {
            #lpbuttonvideo {
              display: block;
              margin-top: 13px;
            }
            table {
              .lpPoweredBy {
                display: none;
              }
            }
          }
        }
      }
    }
    .replenishment {
      display: none;
    }
  }
}

.module-spp-detail {
  .prod_size {
    font-size: 13px;
  }
}

.gnav-drop-down .gnav-drop-down-v1 {
  .help-menu-v1 {
    table.lpStaticButton {
      tr.lpPoweredBy {
        .lpPoweredByDiv {
          display: none;
        }
      }
    }
  }
}

.footer-top-v1 {
  .form-text {
    width: 35%;
  }
  .form-submit {
    width: auto;
  }
}

#header {
  .outer-wrap {
    .column.branding {
      width: 200px;
    }
  }
  .top-right {
    .offer-promo-row {
      .content {
        .headline {
          font-size: 13px;
        }
      }
    }
    .gnav-drop-down-v1 {
      .stores-menu-v1 {
        input.form-text {
          font-size: 12px;
          padding: 0px 0px 0px 10px;
        }
        div {
          display: none;
        }
      }
    }
  }
}

.store-locator-v1 {
  .store-locator-body {
    .store-search-controls {
      .search_container {
        input.search {
          font-size: 11px;
        }
      }
      .sbOptions {
        text-transform: uppercase;
      }
      .search_alternative_text {
        margin: 3px 15px 0;
      }
      .country_container {
        margin: 0 15px;
      }
    }
  }
}

#contact_us {
  #contact-form-wrap {
    .second-column-wrap {
      .registered_member_container {
        label {
          display: block;
        }
      }
    }
  }
}

.contact_us {
  ul.category {
    li p.copy {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

.locations_table_panel {
  #store_results {
    table {
      td.store_name.marker {
        display: table-cell;
      }
      td {
        span.store_name {
          display: block;
        }
      }
    }
  }
}

.passwordreset {
  #content {
    margin-bottom: 95px;
  }
}

.spp_reviews {
  .BVPageBody {
    float: left;
  }
}

.full_sign_in {
  .form {
    .row {
      .form-text {
        line-height: 14px;
        padding: 6px 13px;
      }
    }
  }
}

#sticky-bar {
  #sticky_bar_content {
    .sticky_content {
      .sticky_prod_select {
        .btn-add-to-bag {
          font-size: 14px;
        }
      }
    }
  }
}

html.ie8 {
  #search-wrapper {
    #summary {
      .summary-search-form {
        .form-type-textfield {
          input.search-term {
            border-bottom: 1px solid #bdbcbc;
            border-left: 1px solid #bdbcbc;
            line-height: normal;
            border-top: 1px solid #bdbcbc;
            border-right: 1px solid #bdbcbc;
            padding-top: 6px;
          }
          input.search-again {
            background: url('/sites/clinique/themes/cl_base/template_api/gnav_search_v1/icon-search.png') no-repeat 102%
              center;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.mm_3col_1tout_v1 {
  .explore {
    position: relative;
    width: 100%;
  }
}

html.oldie {
  span.BVFieldRadioContainerYes,
  span.BVFieldRadioContainerNo {
    INPUT[type='radio'] {
      filter: alpha(opacity = 0.01);
    }
  }
}

.three-step-formatter-v1 {
  .three-step-formatter-header {
    .col {
      .title {
        white-space: normal;
      }
    }
  }
}

#signin {
  .inner-block {
    width: 85%;
  }
}

.offer-promo-v1 {
  .headline,
  .cta {
    white-space: normal;
  }
}

.promo_product_row {
  .product.both {
    .button {
      white-space: normal;
    }
  }
}

.form_element.radio.default_ship_container {
  width: 100%;
}

.content {
  .header-nav-formatter-v1 {
    .shortcuts {
      .header-nav-row-v1 {
        .lpPoweredBy {
          display: none;
        }
        .lpEmtStarRating {
          display: none;
        }
        .lpStaticButtonTR {
          a {
            padding: 0 0;
          }
        }
      }
      li.header-nav-row-v1.live-chat a {
        border: none !important;
        background: none !important;
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
      }
      li.header-nav-row-v1.live-chat a img {
        border: none !important;
        background: none !important;
        margin-left: 5px;
        padding: 0;
        width: auto;
        height: 17px;
      }
    }
  }
}

.lpPoweredBy {
  display: none;
}

a.button.disabled,
input.disabled.form-submit,
input.disabled.button[type='submit'] {
  background-color: #bdbcbc !important;
}

.cs-page-section-row-v1 {
  .row {
    .section-link {
      a {
        text-transform: uppercase !important;
        font-size: 19px;
      }
    }
  }
}

.gnav-drop-down-v1 {
  .dd-content {
    width: auto;
  }
}

.pcg-product-large-v1 {
  .mpp-box {
    .btn-quickview {
      width: 150px;
    }
  }
}

.pc_spp_view {
  .BVRRRatingSummary {
    .BVRRSocialBookmarkingLinks {
      a.BVRRSocialBookmarkingSharingLinkPinterest {
        display: none !important;
      }
    }
  }
}

.spp_reviews {
  .BVRRReviewDisplayStyle5Footer {
    .BVRRReviewSocialLinksContainer {
      a.BVRRSocialBookmarkingSharingLinkPinterest {
        display: none;
      }
    }
  }
  .BVPageBody {
    .BVSocialBookmarkingLinks {
      a.BVSocialBookmarkingSharingLinkPinterest {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body {
    .store-locator-v1 {
      .store-locator-body {
        .store-search-controls {
          .button {
            font-size: 12px;
            letter-spacing: 0.01em;
          }
          .search_container input.search {
            font-size: 10px;
            letter-spacing: 0em;
          }
        }
      }
    }
    .full-width-2-row-v1 h3 {
      font-size: 30px;
    }
    #node-27936 .full-width-2-row-v1 {
      background-position: 57% bottom !important;
    }
    #node-27936 .full-width-2-row-v1 .top {
      left: 65% !important;
    }
    .full-width-2-row-v1 .subheading,
    .full-width-2-row-v1 .subheading p {
      font-size: 20px;
    }
  }
}

#search-wrapper.products .results .result .badge {
  .badge-inner.badge_data_1,
  .badge-inner.badge_data_30 {
    display: none;
  }
  .badge-inner.badge_data_1.new,
  .badge-inner.badge_data_30.bestseller {
    display: inline-block;
  }
}

#BVSubmissionPopupContainer {
  .BVDI_BA {
    .BVDI_BAList {
      a.BVDILink {
        width: auto;
      }
    }
  }
}

.pc_spp_view {
  .wrap.page-spp.how_to_use {
    font-size: 15px;
  }
}

.payment-properties.radio-buttons {
  input[name='PAYMENT_OPTION'] {
    margin-left: 5px;
  }
}

.past-purchases {
  .order.accordion-header {
    .trans-id {
      width: 9%;
    }
    .order-status {
      width: 26%;
    }
  }
}

.wrapper {
  #ff_spp {
    .ff_social {
      .ff_social_share {
        li.ff_twitter {
          display: none;
        }
        li.ff_pinterest {
          display: none;
        }
        li.ff_youtube {
          display: block !important;
        }
        li.ff_instagram {
          display: block !important;
        }
        li.ff_youtube a {
          background: url('/media/export/cms/icon-youtube.png') no-repeat scroll 5px 5px transparent;
        }
        li.ff_instagram a {
          background: url('/media/export/cms/icon-instagram.png') no-repeat scroll 5px 5px transparent;
        }
      }
    }
    .ff_spp_product {
      #ff-header {
        h2.h_no_result_sub {
          text-transform: none !important;
        }
      }
    }
  }
}

#ff-wrapper {
  #ff-content {
    #product-results {
      .ff_products_container {
        .prod_details {
          .add-to-bag {
            .btn-add-to-bag {
              line-height: 19px;
            }
          }
        }
      }
    }
  }
}

.content {
  .how_to_use {
    color: #000000;
    font-family: HelveticaNeueLTStd45Light, Arial, sans-serif;
  }
}

#main {
  .container {
    #node-27930 {
      width: 100% !important;
    }
  }
}

.popup-offer-v1 {
  .outer-wrap {
    left: 50%;
    margin-left: -230px;
    h2 {
      font-weight: bold;
    }
    p {
      color: #000000;
    }
    .signup-forms {
      .form-submit-wrap {
        margin: 0 49px;
        .form-text {
          width: auto;
        }
      }
    }
    .subheading p {
      color: #46ab8c;
    }
  }
  .popup-offer-link {
    background-color: #cc0066;
    position: absolute;
    left: 157px;
    padding: 8px 19px;
    font-size: 16px;
    text-align: center;
    a {
      color: #ffffff;
      font-weight: bold;
    }
  }
}

.eh-product {
  .product {
    a.button {
      font-size: 12px;
    }
  }
}

.eh-thewink-hp-redesign-top-stories-v1 {
  .article-title {
    text-transform: none !important;
  }
}

.cs-3-col-row-v1 {
  .customer-care-list {
    .customer-care-list-item {
      width: 100%;
      .bottom {
        position: initial;
      }
    }
  }
}

#foreground-node {
  &.overlay-container {
    &.notify-me-container {
      width: 345px;
      .tiny-waitlist-overlay {
        h2 {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .tooltip-gdpr,
        .align-r,
        p {
          font-size: 12px;
          input {
            font-size: 12px;
          }
        }
        .gray {
          margin: 5px 0 10px 0;
        }
      }
    }
  }
}
