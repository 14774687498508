#main {
  padding-top: 0;
}

.section-why-clinique {
  .image-with-text-v1 {
    .info {
      width: 42%;
      .heading {
        padding-top: 25px;
      }
      .body {
        p {
          font-size: 17px;
        }
      }
    }
  }
}

.store-locator-v1 {
  .store-locator-body {
    .store-search-controls {
      .search_alternative_text {
        margin: 3px 10px 0;
      }
      .search_container {
        input.search {
          width: 170px;
        }
        a.button {
          padding: 4px 6px 3px;
          font-size: 14px;
        }
      }
    }
    .country_container {
      display: none;
    }
    .search_container {
      padding-#{$rdirection}: 15px;
    }
  }
}

.three-step-formatter-rows {
  .promo_product_row {
    .row-info {
      padding: 0 1% 0 4%;
      h3 {
        font-size: 34px;
      }
    }
    .product {
      .btn-shopnow {
        margin-#{$ldirection}: 18%;
      }
      .btn-quickview {
        left: 11%;
      }
    }
  }
}

.power-couples-formatter-header {
  .cols {
    .plus {
      background: transparent
        url(/sites/clinique/themes/cl_base/template_api/power_couples_row_v1/sprite-powercouples.png) right -221px no-repeat;
    }
  }
}

.power_couple_sticky_content {
  .title {
    margin-right: 3%;
  }
  .plus {
    width: 32%;
  }
  .equals {
    width: 23.3%;
  }
}

.power-couples-formatter-v1 {
  .power-couples-formatter-header {
    .col:nth-of-type(1) {
      .title {
        white-space: normal;
      }
    }
  }
  .power-couples-formatter-rows {
    .product {
      .btn-quickview {
        left: 11%;
      }
      .btn-shopnow {
        margin-#{$ldirection}: 18%;
      }
    }
  }
}

.mm-formatter-v1 {
  .mm-menu {
    li.first.signin {
      span.title {
        font-size: 13px;
      }
    }
  }
}

.ecommerce {
  .mpp-grid {
    li {
      &.mpp-box {
        .mpp-product-hover-view {
          .btn-quickview {
            left: 23px;
            width: auto;
          }
        }
      }
    }
  }
  .page-spp {
    .live_chat_text {
      display: none;
    }
    &.spp_reviews {
      display: none;
    }
  }
}

.wrapper {
  #container {
    .blueocean {
      header {
        height: 230px;
      }
    }
  }
}

#vrule {
  top: 65%;
}

#girl {
  margin-top: 100px;
}

#gnav_signin {
  .signin_container {
    .register {
      .login-email {
        width: 39%;
        .form {
          .privacy-form-container,
          .email_promotions {
            span {
              width: 80%;
            }
          }
        }
      }
    }
  }
}

.footer-top {
  .footer-top-v1 {
    max-width: 90%;
    .tel {
      width: 20%;
    }
    .signup-forms {
      width: 64%;
    }
    .social-links {
      width: 12%;
      a.youtube {
        margin-left: 10px;
      }
    }
    .newsletter-signup {
      label {
        width: 55%;
      }
      .form-text {
        width: 32%;
      }
    }
  }
}

#sticky-bar {
  .tertiary-nav {
    li.live-chat {
      padding-top: 10px;
    }
  }
  #sticky_bar_content {
    .sticky_content {
      .sticky_prod_select {
        padding-right: 173px;
        .sbHolder {
          width: 210px;
        }
        .btn-add-to-bag {
          margin: 5px 10px;
          padding: 0 10px;
        }
        .price {
          font-size: 16px;
        }
      }
    }
  }
}

.cl-mpp-product-layout-v1 {
  .mpp-grid {
    ul.view-small {
      li.mpp-box {
        .btn-shopnow {
          margin-#{$ldirection}: 23px;
        }
        .product-image {
          margin-#{$ldirection}: -10px;
        }
        .mpp-product-hover-view {
          width: 184px;
        }
        .badge.badge_103,
        .badge.badge_104,
        .badge.badge_105,
        .badge.badge_106,
        .badge.badge_107,
        .badge.badge_109 {
          background: $color-hot-pink;
          font-size: 11px;
        }
      }
    }
    ul.view-large {
      li.mpp-box {
        .btn-quickview {
          left: 84px;
        }
        .btn-shopnow {
          margin-#{$ldirection}: 95px;
        }
        .mpp-product-hover-view {
          width: 312px;
        }
        .product-image.threeup {
          margin-#{$ldirection}: 0px;
        }
      }
    }
  }
}

.pc-unsubscribe {
  form.validation {
    width: 77%;
    margin: 0 auto;
    padding-top: 1em;
    p {
      margin-bottom: 1em;
    }
  }
}

.mpp-product-group-v1 {
  .mpp-product-v1 {
    .product {
      .btn-quickview {
        left: 28%;
      }
      .btn-shopnow {
        margin-#{$ldirection}: 32%;
      }
    }
  }
}

.section-clinique-happy {
  .product-tout {
    .product {
      .product-title {
        height: 55px;
      }
    }
  }
}

.section-hot-lists {
  .pcg-product-large-v1 {
    &.pcg-product-sku {
      .button.cta {
        margin-#{$ldirection}: 24%;
      }
      #just-bitten-lips & {
        .button.cta {
          margin-#{$ldirection}: 32%;
        }
      }
    }
    .mpp-box {
      .mpp-product-hover-view {
        .btn-shopnow {
          margin-#{$ldirection}: 40%;
        }
      }
    }
  }
}

.section-mascara-finder-collection {
  .mascara-product-v1 {
    .product:hover {
      .btn-quickview {
        left: -57%;
      }
      .btn-shopnow {
        position: absolute;
        left: 31%;
      }
    }
  }
}

.page-product-3-step-liquid-facial-soap {
  .wrap.page-spp.how_to_use.spp-extra.bvflow_hide {
    display: none;
  }
}

#main section.blueocean {
  .skin_diagnostic_results {
    .diagnostic-results {
      .prod-shelf-row {
        ul.products {
          li.product {
            height: auto;
            min-height: 330px;
            position: relative;
            .my-account-sku {
              .btn-add-to-bag {
                position: absolute;
                bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}

.page-products-1599,
.page-products-4034,
.page-products-1673 {
  a.tout-1 {
    display: none;
  }
}

.page-product-moisture-surgetm-72-hour-auto-replenishing-hydrator {
  .col2 {
    .abstract {
      i {
        display: none;
      }
    }
  }
}

.page-product-dark-spot-correctorector {
  .col2 {
    .abstract {
      b {
        display: none;
      }
    }
  }
}

.page-products-1612,
.page-products-1610,
.page-products-1624,
.page-products-1626,
.page-products-12824 {
  li.mpp-box.custom-tout {
    display: none;
  }
}

#node-144606 {
  .popup-offer-v1 {
    .outer-wrap.signup {
      display: none;
    }
  }
}

#node-141648 {
  .product-content-carousel-quickview {
    .pcg-product-image-title-v1 {
      .product {
        .cta.button {
          margin-#{$ldirection}: 20px;
        }
      }
    }
  }
}

.store-locator-body {
  .city_container {
    margin-right: 10px;
  }
}

#header {
  .top-right {
    .inner {
      div:nth-child(3) {
        .gnav-drop-down-v1 {
          .dd-content {
            &.active {
              display: none;
            }
          }
        }
      }
    }
  }
}

.mm-formatter-v1,
#sticky-bar {
  .mm-menu {
    li {
      a {
        color: $color-black;
      }
    }
  }
}

.mm_3col_1tout_v1 {
  .col h2 {
    font-weight: bold;
  }
}

.device-pc {
  .spp_page_wrap {
    .module-spp-detail {
      .container {
        .col1 {
          .badge.badge_103,
          .badge.badge_104,
          .badge.badge_105,
          .badge.badge_106,
          .badge.badge_107,
          .badge.badge_109 {
            background: $color-hot-pink;
            font-size: 11px;
          }
        }
      }
    }
  }
}
