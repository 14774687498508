.loyalty-panels_header,
.m_samples .samples_content .loyalty-panels_header {
  border-top: 1px solid $color-grey;
  background: rgb(255, 255, 255); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(246, 246, 246, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 ); /* IE6-9 */

  .outer-wrap {
    background-repeat: no-repeat;
    padding-bottom: 5px;
    padding-top: 20px;
    @media #{$medium-up} {
      background-image: url('/media/export/cms/loyalty/Loyalty_logo_small.png');
      background-position: 0 -25px;
      padding-top: 60px;
      padding-bottom: 65px;
    }
  }
  h2,
  p {
    @media #{$medium-up} {
      margin-left: 165px;
    }
  }
  h2 {
    color: #000000;
    margin-bottom: 0;
  }
  p {
    color: #5dba98;
    font-size: 26px;
    margin-top: 0;
  }
}

.loyalty-products {
  padding-top: 10px;
  @media #{$medium-up} {
    padding-top: 50px;
  }
  padding-bottom: 40px;
  border-top: 1px solid $color-grey;
  header {
    h3 {
      width: 165px;
      float: left;
      color: #c1c1c1;
      font-size: 3em;
      font-weight: bold;
      color: #c1c1c1;
    }
    h4 {
      float: left;
      font-size: 1.6em;
      line-height: 3em;
      color: #46ab8c;
      @media #{$medium-up} {
        font-size: 2em;
        line-height: 2.2em;
      }
    }
  }
}
