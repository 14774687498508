// CPHelvetica font style
$cpHelveticaLight: 'CPHelvetica-Light';
$cpHelveticaBold: 'CPHelvetica-Bold';
@font-face {
  font-family: '#{$cpHelveticaLight}';
  src: url('#{$netstorage-font-path}cyrilic/CPHelvetica/CPHelvetica-Light.woff2') format('woff2');
  font-display: swap;
}

body,
html,
div,
ul,
li,
a,
p,
span,
table,
tr,
th,
td,
h1,
h2,
h3,
h4,
h5,
input,
form,
dl,
dt,
dd,
label,
small,
select,
article,
strong,
button,
select,
textarea {
  font-family: $cpHelveticaLight, Arial, sans-serif !important;
}

.gnav-block {
  .text-link--style-2-bold,
  a.text-link--style-2-bold,
  .header-gnav-section__panel-link--header span {
    /* stylelint-disable-next-line declaration-no-important */
    font-family: $cpHelveticaBold, Arial, sans-serif !important;
    font-weight: 700;
  }
}
